<template>
  <div v-if="records.length">
    <HashGroup />
    <div>
      <div v-for="(recordSet, setIndex) in recordsWithRepetition" :key="setIndex" class="gridset">
        <div class="jb-grid-container">
          <div v-for="(record, stripIindex) in recordSet" :key="stripIindex" class="jb-grid-item">
            <Card :artist="record.artist" :sideA="record.side.a.title" :sideB="record.side.b.title"
              :customize="record.titleStrip" :record="record" :toolbar="toolbarOptions" />
          </div>
        </div>
        <HashGroup />
        <div class="pageBreak" v-if="recordsWithRepetition.length !== setIndex + 1"></div>
        <img src="/jukebox-tools-wordmark.svg" class="logo" alt="Jukebox tools logo" aria-label="Jukebox tools logo" />
        <HashGroup v-if="setIndex + 1 !== recordsWithRepetition.length" />
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./Card";
import HashGroup from "@/components/45/HashGroup";
import { chunkArray } from "@/components/designer/utils";
import { removeRecordFromPrintSet } from "@/requests";

const TITLESTRIPS_PER_PAGE = 26
export default {
  name: "grid45",
  props: {
    records: {
      type: Array,
      required: true,
    },
    printSetInfo: {
      type: Object,
      required: true,
    },
    repeat: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      toolbarOptions: [
        {
          name: "trash",
          icon: "trash",
          onClick: (record) => {
            removeRecordFromPrintSet(record.printSetId)
            this.$emit("remove", record.printSetId)
          },
        },
      ]
    }
  },
  computed: {
    recordsWithRepetition() {
      return chunkArray(this.records.flatMap(record => Array(this.repeat).fill(record)), TITLESTRIPS_PER_PAGE)
    },
  },

  components: {
    Card, HashGroup
  },
};
</script>

<style scoped>
.jb-grid-container {
  margin-left: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 3in);
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  width: 6.5in;
}

.jb-grid-item {
  width: 3in;
  height: 96.015px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gridset {
  position: relative;
  width: 6.5in;
}

.logo {
  width: 150px;
  top: -40px;
  left: 6.7in;
  position: absolute;
}

.pageBreak {
  background-color: aquamarine;
  height: 2px;
}
</style>
