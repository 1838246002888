export function chunkArray(array, chunkSize) {
  const resultArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    resultArray.push(chunk);
  }
  return resultArray;
}

export function findFirstObjectWithNullId(chunkedArray) {
  for (let i = 0; i < chunkedArray.length; i++) {
    const chunk = chunkedArray[i];
    const item = chunk.find((obj) => obj && obj._id === null);
    if (item) {
      const itemIndex = chunk.indexOf(item);
      return { chunkIndex: i, itemIndex, item };
    }
  }
  return null;
}
/**
 * 
 * @param {*} chunkedArray 
 * @param {*} targetIndex 
 * @returns chunkIndex, itemIndex of a flat targetIndex
 */
export function findIndexesInChunkedArray(chunkedArray, targetIndex) {
  let currentIndex = 0;

  for (let chunkIndex = 0; chunkIndex < chunkedArray.length; chunkIndex++) {
    const chunk = chunkedArray[chunkIndex];
    if (currentIndex + chunk.length > targetIndex) {
      const itemIndex = targetIndex - currentIndex;
      return { chunkIndex, itemIndex };
    }
    currentIndex += chunk.length;
  }

  return null;
}

/**
 * deepShuffleArray
 * @param {*} arr  Array of arrays to shuffle
 * @returns   An array of arrays with the same size as the input array, but with the elements shuffled
 */
export function deepShuffleArray(arr) {
  // Flatten the array of arrays
  const flatArray = arr.flat();

  // Shuffle the flat array
  for (let i = flatArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [flatArray[i], flatArray[j]] = [flatArray[j], flatArray[i]];
  }

  // Chunk the flat array back into arrays of the original size
  const chunkedArray = [];
  const chunkSize = arr[0].length;
  for (let i = 0; i < flatArray.length; i += chunkSize) {
    chunkedArray.push(flatArray.slice(i, i + chunkSize));
  }

  return chunkedArray;
}

/**
 * Shuffle order of Array
 * @param {*} array  Array to shuffle
 * @returns   An array with the same elements as the input array, but shuffled
 */
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function findIndexById(chunkedArray, id) {
  for (let i = 0; i < chunkedArray.length; i++) {
    for (let j = 0; j < chunkedArray[i].length; j++) {
      if (chunkedArray[i][j]._id === id) {
        return { chunkIndex: i, itemIndex: j };
      }
    }
  }
  return null; // Return null if the element with the specified _id is not found
}