<template>
    <div class="hashGroup mb-1 hash-l">
        <div class="hash border-left border-right"></div>
        <div class="hash border-right"></div>
    </div>
</template>
  
<script>
export default {
    name: "HashGroup",
};
</script>
  
<style scoped>
.hashGroup {
    display: grid;
    grid-template-columns: repeat(2, 3.01in);
    grid-column-gap: 0;
    grid-row-gap: 0;
    margin-left: 2.5rem;
}

.hash {
    border-color: #000 !important;
    height: 60px;
}
</style>
  