<template>
  <div class="align">
    <div v-for="(song, index) in records" v-bind:key="song.id" class="grid  ml-5"
      :class="{ pageBreak: index % pageBreak === (pageBreak - 1) }" @click="showModal(song)">
      <Card :artist="song.artist || artist" :title="song.side.a.title" />
      <Card :artist="song.artist || artist" :title="song.side.b.title" />
    </div>
  </div>
</template>

<script>
import Card from "./Card";

export default {
  name: "grid78",
  data() {
    return {
      pageBreak: 29, // 15
    };
  },
  props: {
    records: Array
  },
  components: {
    Card,
  },
};
</script>

<style scoped>
.align {
  margin: auto;
  margin-top: 0 !important;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2px;
  width: fit-content;
}
</style>
